<template>
<div>
  <template>
    <div id="breadcrumb-alignment" class="mb-1">
      <div class="d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb
          :items="breadCrumb()"
        />
      </div>
    </div>
  </template>

  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Add Section">
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        

        <b-row>
                
          <b-col md="12" >
            
            <b-form-group
              label="Project Site"
              class="required"

            >
              <b-form-select v-model="form.site">

                <b-form-select-option value="" disabled>Select</b-form-select-option>
                
                <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                
                
              </b-form-select>
                
            </b-form-group>
          </b-col>

        </b-row>

        <div class="fsmTable table-responsive" style="margin-bottom: 0px !important;">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col" style="width: 95%">Section Name <span class="text-danger">*</span></th>
                        <th scope="col" style="width: 5%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in form.sections" :key="index">
                        <td>
                            <b-form-input autocomplete="off" placeholder="3 to 100 characters" v-model="item.item"/>
                        </td>
                        <td class="text-center">
                            <b-button class="float-right" variant="danger" @click="removeRow(index);">-</b-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- <b-row>
          <b-col md="12">

            <b-form-group
              label="Section Name"
              class="required mb-0"
            >
            </b-form-group>

          </b-col>
        </b-row> -->

        <b-row>
          
          <b-col md="12">
            <!-- <div v-for="(item,index) in form.sections" :key="index">
              <b-row class="mb-1">
                <b-col md="10">
                  
                    <b-form-input
                      autocomplete="off"
                      placeholder="3 to 100 characters"
                      v-model="item.item"
                    />
                  
                </b-col>

                <b-col md="2">
                  <b-button class="float-right" variant="danger" @click="removeRow(index);">-</b-button>
                </b-col>

              </b-row>


            </div> -->

            <b-row class="mb-1">
                <b-col md="12" class="text-center">
                  <hr>
                    <b-button class="mt-1 under_construct_mobile_add_more_btn" variant="primary" @click="addRow">Add More</b-button>
                </b-col>
            </b-row>
            
            
            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.push({ name:'sections', params: { site_id: $route.params.site_id } })"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  
</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BBreadcrumb
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'


export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BFormTimepicker,
    VueTimepicker,
    BBreadcrumb
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      
      error_message:null,
      showDismissibleAlert:false,
      form:{ 
        site:'',
        sections :[{
          item:''
        }]
      },
      sites:[],
      
      
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form
           },
           api: '/api/add-sections'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Added Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name:'sections', params: { site_id: this.$route.params.site_id } })
                });
                
            }
        });
      
    },

    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
              role:this.$store.getters.currentUser.role,
              om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;
                return this.sites;
            }
        });
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:{name:'custom-forms'},
        text: 'Custom Forms',
      },{
        to:{name:'external-checklist-report'},
        text:'Micron External Checklist Report',             
      },{
        to:{name:'sections',params: { site_id: this.$route.params.site_id }},
        text: 'Sections',
        
      },{
        to:null,
        text:'Add Section',
        active:true
      }];
      return item;
    },
    addRow(){
        this.form.sections.push({
          item:''
        })
    },

    removeRow(index) {
      
      this.form.sections.splice(index, 1);

      if(this.form.sections.length == 0){
          this.form.sections.push({
            item:''
          })
      }
    },
    

  },
  mounted(){
    this.allSites().then(()=> {
      this.form.site = this.$route.params.site_id ? this.$route.params.site_id : '';
    });
  }
}
</script>
